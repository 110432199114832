import { Formik, Form, FormikHelpers } from 'formik';
import classnames from 'classnames';
import { t } from '@lingui/macro';
import { Button } from '@iwoca/orion';

import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import { logErrorToDataLayer } from './googleTracking';
import * as products from './products';
import * as countryHelpers from './countryHelper';
import * as formHelpers from './form/form';
import * as DEForm from './form/DEFormFields';
import { UKFormSchema } from './form/formSchema';
import MarketingCheckbox from './form/MarketingCheckbox';
import EmailField from './form/EmailField';
import AmountField from './form/AmountField';

import styles from './App.module.css';
import { useLingui } from '@lingui/react';
import { LengthOfLoanField } from './form/LengthOfLoanField';
import { PurposeField } from './form/PurposeField';

type AppProps = {
  appVersion?: string | undefined;
};

export const SIGNUP_PAGE_URL = {
  uk: '/account/signup',
  de: '/konto/anmeldung',
  de_white_label: '/kreditwunsch',
};

function App({ appVersion }: AppProps) {
  const productName = products.useProductName();

  const { minAmount, maxAmount: defaultMaxAmount } =
    products.getProductConfig(productName);

  const lingui = useLingui();
  const isDE = countryHelpers.isDELocale(
    lingui.i18n.locale as countryHelpers.SupportedLocales
  );

  const maxAmount = isDE ? 500000 : defaultMaxAmount;

  const handleSubmitForm = async (
    values: formHelpers.FormValues,
    formik: FormikHelpers<formHelpers.FormValues>,
    appVersion: string | undefined
  ) => {
    if (!values['applicantEmailAddress']) {
      formik.setSubmitting(false);
      return;
    }

    try {
      // @ts-expect-error
      Amplitude.track({
        ...AmplitudeWebsite.START_APPLICATION,
        payload: {
          no_amount_selected: undefined,
        },
      });
      await formHelpers.sendApplicationDataToGA(values);
      await formHelpers.submitSignupForm(values, productName, appVersion, isDE);
    } catch (error) {
      logErrorToDataLayer('Step Zero - Form submission error');

      formik.setSubmitting(false);
      if (typeof window !== 'undefined') {
        // @ts-expect-error
        window.Raven.captureException(error);
      }
    }
  };

  const initialValues: formHelpers.FormValues = {
    applicantEmailAddress: '',
    requestedAmount: undefined,
    purpose: undefined,
    email_marketing_opt_in: false,
    lengthOfLoan: undefined,
    no_amount_checkbox: false,
  };

  const applyCTA =
    productName === null ? t`Apply now` : t`Apply for a ${productName}`;

  const UKFormSettings = {
    initialValues,
    validationSchema: UKFormSchema(productName),
  };
  return (
    <Formik
      onSubmit={(values, formik) => {
        handleSubmitForm(values, formik, appVersion);
      }}
      validateOnChange={false}
      {...(isDE ? DEForm.DEFormSettings : UKFormSettings)}
    >
      {(props) => {
        const { isSubmitting, values } = props;

        const showDetailedPurposeFieldDE =
          (values as DEForm.DEFormValues).purpose === 'other';

        return (
          <Form data-testid="stepZeroForm">
            <div className={styles.formGroupContainer}>
              <EmailField form={props} />
              <AmountField
                minAmount={minAmount}
                maxAmount={maxAmount}
                validateField={props.validateField}
                disabled={values.no_amount_checkbox}
                isDE={isDE}
                appVersion={appVersion}
              />

              {/* App version used to control a split test defined by marketing, it is a data attribute passed down via the root component.*/}
              {!isDE && (
                <LengthOfLoanField disabled={values.no_amount_checkbox} />
              )}

              {!isDE && <PurposeField />}

              {isDE ? (
                <>
                  <DEForm.PurposeField />
                  <DEForm.LengthOfLoanField />
                  {showDetailedPurposeFieldDE && (
                    <DEForm.DetailedPurposeField />
                  )}
                </>
              ) : null}
            </div>

            {isDE ? <DEForm.PersonalProcessingAgreement /> : null}

            <div className={styles.bottom}>
              <MarketingCheckbox />

              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                  className={classnames(styles.submitButton, {
                    [styles.submitting]: isSubmitting,
                  })}
                >
                  {isSubmitting ? t`Submitting` : applyCTA}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default App;
